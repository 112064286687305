//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: 											#ffffff;

// Theme colors
// Primary
$primary:       									#65737E;
$primary-active:    								#4c5964;
$primary-light:    								    #F0F1F3;
$primary-light-dark:    							#343D46;
$primary-inverse:  									$white;

// Success
$success:       								  	#50cd89;
$success-active:    								#47be7d;
$success-light:    									#e8fff3;
$success-light-dark:    							#1c3238;
$success-inverse:  									$white;

// Info
$info:       									    #7239ea;
$info-active:    									#5014d0;
$info-light:    									#f8f5ff;
$info-light-dark:    								#2f264f;
$info-inverse:  									$white;

// Danger
$danger:       									    #f1416c;
$danger-active:    									#d9214e;
$danger-light:    									#fff5f8;
$danger-light-dark:    								#3a2434;
$danger-inverse:  									$white;

// Warning
$warning:       									#ffc700;
$warning-active:    								#f1bc00;
$warning-light:    									#fff8dd;
$warning-light-dark:    							#392f28;
$warning-inverse:  									$white;

// Grays

$gray-100: #F0F1F3 !default;
$gray-200: #D9DCE1 !default;
$gray-300: #C0C5CE !default;
$gray-400: #A7ADBA !default;
$gray-500: #80909C !default;
$gray-600: #65737E !default;
$gray-700: #576571 !default;
$gray-800: #4F5B66 !default;
$gray-900: #343D46 !default;

// Grays - Dark
$gray-100-dark:  #343D46 !default;
$gray-200-dark:  #4F5B66 !default;
$gray-300-dark:  #576571 !default;
$gray-400-dark:  #65737E !default;
$gray-500-dark:  #80909C !default;
$gray-600-dark:  #A7ADBA !default;
$gray-700-dark:  #C0C5CE !default;
$gray-800-dark:  #D9DCE1 !default;
$gray-900-dark:  #F0F1F3 !default;
