//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
	.app-content {
			padding-top: 0;
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.app-content { 
			padding-top: 0;
	}
}