
.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc { /* the calendar root */
  
  margin: 0 auto;
}

.fc .fc-daygrid-day-frame:hover {
	background-color: var(--kt-primary-light)
}

.fc .fc-daygrid-day-events {
	display: flex;
	flex-wrap: wrap;
}

