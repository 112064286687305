//
// Variables
//

// Reboot
$app-bg-color: #f5f5f5;
$app-bg-color-dark: #151521;
$app-blank-bg-color: $white;
$app-blank-bg-color-dark: $app-bg-color-dark;

// Header base
$app-header-base-height: 70px;
$app-header-base-height-mobile: 60px;
$app-header-base-bg-color: #f5f5f5;
$app-header-base-bg-color-dark: #1e1e2d;
$app-header-base-bg-color-mobile: $app-header-base-bg-color;
$app-header-base-bg-color-mobile-dark: $app-header-base-bg-color-dark;
$app-header-base-box-shadow: 0px 30px 20px 0px rgb(205 207 210 / 10%);
$app-header-base-box-shadow-dark: none;
$app-header-base-box-shadow-mobile: $app-header-base-box-shadow;
$app-header-base-box-shadow-mobile-dark: $app-header-base-box-shadow-dark;
$app-header-base-menu-link-bg-color-active: #8c8b8b;
$app-header-base-menu-link-bg-color-active-dark: #2A2A3C;

// Header light
$app-header-light-separator-color: #E4E6EF;
$app-header-light-separator-color-dark: $border-color-dark;

// Header dark
$app-header-dark-bg-color: #1e1e2d;
$app-header-dark-separator-color: #282a3d;
$app-header-dark-scrollbar-color: #3b3b64;
$app-header-dark-scrollbar-color-hover: lighten($app-header-dark-scrollbar-color, 3%);

// Sidebar base
$app-sidebar-base-width: 265px;
$app-sidebar-base-width-mobile: 250px; 

$app-sidebar-base-toggle-btn-box-shadow: 0px 0px 10px rgba(113, 121, 136, 0.1);
$app-sidebar-base-toggle-btn-box-shadow-dark: none;
$app-sidebar-base-toggle-btn-bg-color: $body-bg;
$app-sidebar-base-toggle-btn-bg-color-dark: $app-header-base-menu-link-bg-color-active-dark;

// Sidebar minimize
$app-sidebar-minimize-width: 75px;

// Sidebar light
$app-sidebar-light-bg-color: $body-bg;
$app-sidebar-light-bg-color-dark: #1e1e2d;

$app-sidebar-light-box-shadow: 0 0 28px 0 rgba(82,63,105,.05);
$app-sidebar-light-box-shadow-dark: none;

$app-sidebar-light-separator-color: $app-header-light-separator-color;
$app-sidebar-light-separator-color-dark: $app-header-light-separator-color-dark;

$app-sidebar-light-scrollbar-color: $gray-200;
$app-sidebar-light-scrollbar-color-dark: $gray-200-dark;
$app-sidebar-light-scrollbar-color-hover: $gray-200;
$app-sidebar-light-scrollbar-color-hover-dark: $gray-200-dark;
$app-sidebar-light-menu-heading-color: #B5B5C3;
$app-sidebar-light-menu-heading-color-dark: $gray-500-dark;
$app-sidebar-light-menu-link-bg-color-active: #00339a;
$app-sidebar-light-menu-link-bg-color-active-dark: #2A2A3C;
$app-sidebar-light-header-menu-link-bg-color-active: #ebebeb;
$app-sidebar-light-header-menu-link-bg-color-active-dark: $gray-100-dark;
    
// Sidebar dark
$app-sidebar-dark-bg-color: #1e1e2d;
$app-sidebar-dark-separator-color: #393945;
$app-sidebar-dark-scrollbar-color: $gray-300-dark;
$app-sidebar-dark-scrollbar-color-hover: $gray-300-dark;
$app-sidebar-dark-menu-heading-color: #646477;
$app-sidebar-dark-menu-link-bg-color-active: #2A2A3C;

// Toolbar base
$app-toolbar-base-height: 55px;
$app-toolbar-base-bg-color:$body-bg;
$app-toolbar-base-bg-color-dark: darken(#1e1e2d, 2%);
$app-toolbar-base-bg-color-mobile: $app-toolbar-base-bg-color;
$app-toolbar-base-bg-color-mobile-dark: $app-toolbar-base-bg-color-dark;
$app-toolbar-base-box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.05);
$app-toolbar-base-box-shadow-dark: none;
$app-toolbar-base-box-shadow-mobile: $app-toolbar-base-box-shadow;
$app-toolbar-base-box-shadow-mobile-dark: $app-toolbar-base-box-shadow-mobile;
$app-toolbar-base-border-top: 1px solid $border-color;
$app-toolbar-base-border-top-dark: 0;
$app-toolbar-base-border-top-mobile: $app-toolbar-base-border-top;
$app-toolbar-base-border-top-mobile-dark: $app-toolbar-base-border-top-mobile;

// Footer
$app-footer-height: 60px;
$app-footer-height-mobile: auto;
$app-footer-bg-color: $body-bg;
$app-footer-bg-color-dark: #1e1e2d;
$app-footer-bg-color-mobile: $app-footer-bg-color;
$app-footer-bg-color-mobile-dark: $app-footer-bg-color-dark;

// Scrolltop
$scrolltop-bottom: 20px;
$scrolltop-bottom-mobile: 20px;
$scrolltop-end: 30px;
$scrolltop-end-mobile: 20px;
$scrolltop-bg-color: #aeaeae;
$scrolltop-bg-color-dark: #6e7082;
$scrolltop-bg-color-hover: #aeaeae;
$scrolltop-bg-color-hover-dark: #6e7082;

// Layout builder toggle(used for Demo purposes only)
$app-layout-builder-toggle-end: 50px;
$app-layout-builder-toggle-end-mobile: 40px;
$app-layout-builder-toggle-bottom: 40px;
$app-layout-builder-toggle-bottom-mobile: 20px;


$header-config: (
	// Desktop Mode
	desktop: (
		// Default Mode
		default: (
			height: 120px
		),

		// Fixed Mode
		fixed: (
			height: 65px,
			z-index: 97,
			bg-color: $white,
			box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.05)
		)
	),

	// Tablet & Mobile Modes
	tablet-and-mobile: (
		// Default Mode
		default: (
			height: 60px,
		),

		// Fixed Mode
		fixed: (
			z-index: 97,
			height: 55px,
			bg-color: $white,
			box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.05)
		)
	)
) !default;

// Sidebar
$sidebar-config: (
	// General Mode
	general: (
		bg-color: #fff
	),

	// Desktop Mode
	desktop: (
		width: 400px,
		z-index: get($header-config, desktop, fixed, z-index) + 1,
		transition-speed: 0.3s
	)
	


) !default;

// Aside
$aside-config: (
	// Base
	base: (
		primary-width: 100px, // Aside's primary panel's width for desktop mode(see "aside-primary" class)
		secondary-width: 270px,  // AsideAside's primary panel's width for desktop mode(see "aside-secondary" class)
		primary-width-tablet-and-mobile: 70px, // Aside's primary panel's width for mobile mode(see "aside-primary" class)
		transition-speed: 0.3s, // General transition
		box-shadow: none, //5px 0px 10px rgba(70, 78, 95, 0.05), // Aside's box shadow
		z-index: 98, // Aside's z-index property
		scroll-width: 4px // Aside's custom scrollbar's width
	)
) !default;

// Sidebar
$sidebar-config: (
	// General Mode
	general: (
		bg-color: $accent-color
	),

	// Desktop Mode
	desktop: (
		width: 400px,
		z-index: get($header-config, desktop, fixed, z-index) + 1,
		transition-speed: 0.3s
	)

	
) !default;

.right-sidebar-header {
	[data-kt-scrolltop="on"] & {
		box-shadow: var(--kt-app-header-base-box-shadow);
	}
}

.react-datepicker__header {
  background-color:  var(--kt-input-solid-bg);
}

.react-datepicker-popper {
  z-index: 3;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 0.475rem !important;
}
